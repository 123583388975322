@import "@king-ict/eupisi-ui/styles/variables.scss";
@import "@king-ict/eupisi-ui/styles/typography.scss";

:global(.alert-primary).infoPanel {
  display: flex;
  align-items: center;
  @include theme-value("color", primary);
  @include theme-value("background-color", light-primary);
  @include theme-value("border-color", light-primary);
  padding: 9px 20px;
}

:global(.alert-success).infoPanel {
  display: flex;
  align-items: center;
  @include theme-value("color", success);
  @include theme-value("background-color", light-success);
  @include theme-value("border-color", light-success);
  padding: 9px 20px;
}

:global(.alert-danger).infoPanel {
  display: flex;
  align-items: center;
  @include theme-value("color", danger);
  @include theme-value("background-color", light-danger);
  @include theme-value("border-color", light-danger);
  padding: 9px 20px;
}

:global(.alert-warning).infoPanel {
  display: flex;
  align-items: center;
  @include theme-value("color", warning);
  @include theme-value("background-color", light-warning);
  @include theme-value("border-color", light-warning);
  padding: 9px 20px;
  line-height: 20px;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
}

.message {
  padding: 0;
}

.message > * {
  display: inline-block;
}

.fullWidth {
  width: 100%;
  @include base;
  .message {
    padding: 6px 0;
  }
}

.autoWidth {
  width: fit-content;
  @include small;
}

:global(.theme-vision-impaired) {
  .infoPanel {
    color: white !important;
    background-color: black !important;
    border-color: black !important;
  }
}
