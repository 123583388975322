.about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
}

.title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
}

.paragraph {
    margin-right: 500px;
}